@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .user-group-form {
    h5 {
      margin-bottom: 40px;
    }

    .form-group {
      min-width: map-get($container-max-widths, "md");
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      input[type="checkbox"] {
        width: 20px;
      }
    }
    .form-group > label {
      width: calc(70% - 20px);
    }
    .action-btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .user-group-form {
    h5 {
      margin-bottom: 40px;
    }

    .form-group {
      min-width: map-get($container-max-widths, "sm");
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 20px;

      input[type="checkbox"] {
        width: 20px;
      }
    }

    .form-group > label {
      width: calc(70% - 20px);
    }

    .action-btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .user-group-form {
    h5 {
      margin-bottom: 40px;
    }

    .form-group {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .form-group > label {
        width: 100%;
      }

      &.cb {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        label {
          margin: 0 10px 0 0;
        }
      }

      input[type="checkbox"] {
        width: 20px;
      }
    }

    .action-btns {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      button {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }
}
