.waste-accumulation-form {
  margin-bottom: 15rem;
  textarea {
    display: flex;
    align-items: center;
    min-height: 6rem;
  }
  font-size: 16px;

  form {
    .form-row {
      display: grid;

      &.grid2 {
        grid-template-columns: 60% 40%;
      }

      &.grid1 {
        grid-template-columns: 100%;
      }

      label {
        font-size: 18px;
      }

      .cell-grid {
        padding: 10px;
      }
    }
  }
}
