.HEADER {
  .HEADER__menu {
    li {
      border-radius: 5px;
    }

    a {
      padding: 10px;

      &.nav-on {
        background-color: var(--plum-waste-darker-color);
        display: block;
        border-radius: 4px;
      }
    }
  }
}

.sortHolder {
  width: 10rem;
  --height: 35px;
  font-size: 14px;
}
.sortFilterHeader {
  justify-content: flex-end;
  display: flex;
  align-items: center;
}
.sortFilterHeader button {
  //   margin-bottom: 0;
}

.main_wrapper {
  padding: 0 7.25%;
}

.site-options {
  width: 100%;
}

@media only screen and (max-width: 1200px) {
  .HEADER {
    .HEADER__menu {
      .main-site {
        min-width: auto;
      }
    }
  }
}

@media only screen and (min-width: 1201px) {
  .HEADER {
    .HEADER__menu {
      .main-site {
        min-width: 400px;
      }
    }
  }
}
