.user-management {
  .userMgt_plus {
    fill: var(--plum-waste-pry-color);
  }
  .globalKeyword_input_wrapper {
    --height: 43px;
  }

  .tab-content-header {
    display: block !important;

    .table-actions {
      display: flex;
      gap: 1rem;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      flex-wrap: wrap;

      .button_navigation {
        display: flex;
        flex: 3;
        gap: 10px;
        .userMgtNavLink {
          flex: 1;
          display: flex;
          text-decoration: none;
        }
        .btn {
          // align-self: flex-end;
          flex: 1;
          height: 100% !important;
        }
        .tile {
          display: flex;
          flex: 1;
          height: auto !important;
          align-self: stretch;
          &.spbtw {
            gap: 0.5rem;
          }
        }
      }

      .searchbox-wrapper {
        flex: 1;
      }
    }
  }
}
