.locale_balloon_wrapper {
  width: 3.2rem;
  position: fixed;
  bottom: 5.25rem;
  right: 1.5rem;
  z-index: 99;
}

.floating_locale_bar {
  position: absolute;
  right: 3.1rem;
}

.locale_balloon_container {
  --size: 2rem;
  --shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
  width: var(--size);
  height: var(--size);
  z-index: 999;
  box-shadow: var(--shadow);
  background-color: #fff;
  border-radius: 50%;
}

.locale_balloon_container:hover {
  background-color: rgb(249, 249, 249);
}
.locale_balloon_container .locale_balloon_icon_wrapper {
  cursor: pointer;
  padding: 0.35rem;
}
