.feature_drop_down_container {
  height: 2.625rem;
  border-radius: 3px;
  padding: 0;
  padding-left: 1rem;
}
.feature-list input[read-only] {
  background-color: rgba(238, 238, 238, 0.6) !important;
  appearance: none;
  border: 1px solid transparent !important;
}

.feature_list_options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.feature_titles {
  margin-bottom: 2rem;
}
.feature_titles > div {
  word-wrap: anywhere;
  padding: 0.35rem;
  align-self: stretch;
  border-radius: 2px;
  font-weight: 500;
  border: 1px solid var(--borderColor) !important;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.2),
    0 1px 3px 1px rgba(60, 64, 67, 0.1);
  border: 0;
  display: flex;
  background-color: var(--plum-waste-light-color);
  gap: 0.5rem;
  box-sizing: border-box;
  font-weight: 600;
  align-items: center;
  width: 100%;
}

.feature_list_option {
  padding: 0.5rem 0;
  padding-top: 0;
  border-bottom: 1px solid #adadad7e;
}
.feature_list_option,
.feature_titles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
.feature_list_option > *,
.feature_titles > * {
  min-width: 0;
  flex: 1;
  display: flex;
  gap: 0.5rem;
  margin-bottom: 0;
}
.feature_popup_wrap {
  padding: 2rem min(10rem, 10%) !important;
}
