.int_popUp_wrapper {
  --opacity: 0.4;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  box-shadow: 0px 3px 3px #00000029;
  border: 1px solid #9f9f9f;
  border-radius: 0px 0px 2px 2px;
}

.int_popUp_wrapper label {
  width: fit-content;
  cursor: pointer;
  margin: 0;
  transition: none;
}

.int_popUp_container {
  position: relative;
  padding: 3.3rem 5rem;
  background-color: #fff;
}

.int_popUp_container p {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.25rem;
  font-weight: 700;
}

.int_popUp_closeIcon_wrapper {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}

.int_popUp_btn_wrapper {
  display: flex;
  justify-content: space-between;
  gap: 0 4.5rem;
}

.int_popUp_btn_container {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.85rem 0;
}
.int_popUp_btn_container.invisible {
  visibility: none;
}
.int_popUp_btn_container > div {
  min-width: 0;
}

.int_popUp_wrapper label:hover svg,
.int_popUp_wrapper label:hover {
  color: var(--plum-waste-pry-color);
  fill: var(--plum-waste-pry-color);
}

.int_popUp_btn_container svg {
  transition: none;
}

.int_popUp_btn_container svg:hover {
  opacity: 1;
}

.int_popUp_btn {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  background-color: var(--plum-waste-light-color);
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  padding: 1rem 2rem;
  margin-bottom: 0.5rem;
  min-width: 7.8125rem;
}
.int_popUp_btn[disabled] {
  opacity: var(--opacity);
  pointer-events: none;
}

.int_popUp_btn:hover {
  background-color: #c3e0c4;
  box-shadow: 0px 1px 1px #00000029;
}

.int_popUp_type_label[aria-disabled="true"] {
  pointer-events: none;
  opacity: var(--opacity);
}

.int_popUp_type {
  display: inline-flex;
  gap: 0.5rem;
}

.int_popUp_type_lnk {
  text-decoration: underline;
  text-transform: uppercase;
}
.int_popUp_type_upload_icon_wrapper {
  height: 1.5rem;
  width: 1.5rem;
}
.int_popUp_loaded {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  visibility: hidden;
  color: var(--plum-waste-pry-color);
}
.int_popUp_loaded.visible_load {
  visibility: visible;
}

.integration_hidden_input {
  height: 0;
  display: none;
}
