.invoice_popup_container {
  display: flex;
  margin: auto 0;
  background-color: #fff;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding: 1.5rem 0;
  --amountWidth: 6rem;
  --invoiceWeight: 600;
  position: relative;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}
.invoice_popup_title {
  text-align: center;
  margin-bottom: 2rem;
  width: 100%;
}
.invoice_popup_logo {
  width: 10rem;
  display: flex;
  align-items: flex-start;
}
.invoice_popup_logo img {
  height: auto;
}

.invoice_popup_title h4 {
  font-size: 1.35rem;
  margin-bottom: 2.5rem;
  font-weight: var(--invoiceWeight);
}
.invoice_popup_title p {
  margin: 0;
  margin-bottom: 0.25rem;
  font-size: 1.125rem;
}
.invoice_popup_title p.invoice_date {
  font-size: 0.9rem;
}
.invoice_popup_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2.5rem;
  overscroll-behavior: contain;
  border-bottom: 1px solid var(--borderColor);
}

.invoice_due_date {
  width: 100%;
  text-align: left;
  margin-bottom: 1rem;
}

.invoice_popup_details_wrapper {
  display: grid;
  gap: 2rem 18%;
  grid-template-columns: 1fr 1fr;
  min-width: 0;
  width: 100%;
}
.invoice_popup_details_wrapper.extend {
  padding-top: 4rem;
}
.invoice_popup_details_wrapper.head {
  padding-bottom: 1.5rem;
}
.invoice_popup_details_container {
  max-width: 20rem;
  min-width: 0;
  display: flex;
  overflow-wrap: anywhere;

  flex-direction: column;
}
.invoice_popup_details_container p {
  margin: 0;
}

.invoice_popup_details_container p.invoice_holder {
  margin-bottom: 1rem;
  font-weight: var(--invoiceWeight);
}

.invoice_popup_details_container p.split,
.invoice_popup_details_container .head {
  margin-bottom: 1rem;
}
.invoice_popup_details_container .head {
  font-weight: var(--invoiceWeight);
}
.invoice_popup_table {
  width: 100%;
  font-size: 0.95rem;
}
.invoice_popup_table_wrapper {
  display: grid;
  min-width: 0;
  border-bottom: 2px solid var(--borderColor);
  grid-template-columns:
    minmax(3rem, 1fr) minmax(4rem, 1fr) 4fr repeat(3, 1.8fr) minmax(4rem, 1.2fr)
    var(--amountWidth);
}
.invoice_popup_table_wrapper:first-child {
  font-weight: var(--invoiceWeight);
}
.invoice_popup_table_wrapper:first-child div {
  display: flex;
  align-items: center;
}

.invoice_popup_table_container {
  min-width: 0;
  overflow-wrap: anywhere;
}
.invoice_popup_table_container.wasteDesc {
  text-transform: capitalize;
}
.invoice_popup_table_container div {
  padding: 0.5rem;
}
.invoice_popup_table_container:nth-child(1) {
  overflow-wrap: anywhere;
}
.invoice_popup_table_container:last-child {
  display: flex;
  justify-content: center;
  overflow-wrap: anywhere;
  border: none;
}

/* 


 */

.invoice_total_container {
  padding: 1rem 0;
  width: 100%;
}
.invoice_total_container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 0.25rem 2rem;
  font-weight: var(--invoiceWeight);
}
.invoice_total_label {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.invoice_popup_details_edit_wrap {
  --inputHeight: 2rem;
  --shiftSpace: 0.25rem;
  flex-basis: content;
  display: flex;
  align-items: flex-start;
}

.invoice_popup_details_edit_wrap.mtS,
.invoice_popup_details_edit_wrap.email {
  margin-top: 0.5rem;
  margin-left: 0;
}

/* For first child of address ...the label... */
.invoice_popup_details_edit_wrap > span:first-child {
  padding-right: var(--shiftSpace);
  margin-right: 0;
}
.invoice_popup_details_edit_wrap > p,
.invoice_popup_details_edit_wrap > span.flex1 {
  padding-left: var(--shiftSpace);
  min-height: var(--inputHeight);
  margin-right: 1rem;
}
.invoice_popup_details_edit_wrap.split {
  margin-bottom: 1rem;
}
.invoice_popup_details_edit_wrap textarea,
.invoice_popup_details_edit_wrap input {
  resize: none;
  /* border-color: transparent !important; */
  margin-right: 1rem;
  height: 40px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  /* margin-top: -0.25rem; */
  padding: 0;
  padding: calc(var(--shiftSpace) - 1px);
}
.invoice_popup_details_edit_wrap textarea {
  height: 8rem;
  border: 1px solid var(--borderLteColor);
}
.invoice_popup_details_edit_wrap input {
  height: auto !important;
  display: flex;
  text-align: left;
  border: 1px solid var(--borderLteColor);
}
.invoice_popup_details_edit_wrap textarea:read-only,
.invoice_popup_details_edit_wrap input:read-only {
  padding: 0.25rem;
}
.invoice_total_value {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow-wrap: anywhere;
  /* width: var(--amountWidth); */
}

.invoice_total_word_container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid var(--borderColor);
}
.invoice_total_word_container > span {
  text-transform: capitalize;
}
.invoice_due_date b,
.invoice_total_word_container b {
  margin-right: 1rem;
}
/* 


*/
.invoice_popup_footer_wrapper {
  width: 100%;
  margin-top: 4rem;
}
.invoice_popup_footer_wrapper .pseudo_border {
  height: 3px;
  background: linear-gradient(to right, #818cc4, #818cc4, #f9ab49, #eb6d57);
}
.invoice_footer_column_wrapper {
  margin-top: 2rem;
  display: grid;
  --gap: 1.5rem;
  gap: 2.5rem;
  grid-template-columns: 1fr 3.75fr;
}

.invoice_footer_column_wrapper > div {
  min-width: 0;
}
.invoice_footer_column_cont {
  overflow-wrap: anywhere;
  font-size: 0.95rem;
  min-width: 0;
}
.invoice_footer_column_cont img {
  height: auto;
}
.invoice_footer_column_cont:first-child {
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
}

.invoice_footer_column_cont.main {
  display: flex;
  flex-direction: column;
}
.invoice_footer_column_cont.main > div:first-child {
  font-weight: 500;
  font-weight: 600;
}

.invoice_footer_column_cont.main > div {
  display: grid;
  gap: var(--gap);
  grid-template-columns: 1fr 1fr 1fr;
  min-width: 0;
  width: 100%;
}
.invoice_footer_column_cont.main > div > div {
  min-width: 0;
  padding: 0.5rem 0;
}

/* 



*/

.invoice_popup_btn_wrapper {
  padding: 3rem;
  width: max-content;
  max-width: 80%;
}
.invoice_popup_btn_check_wrap {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  min-height: 3.5rem;
}
.invoice_popup_btn_check_wrap > span {
  display: flex;
  padding: 0 1rem;
}

.invoice_popup_btn_check_wrap input {
  max-width: 12rem;
}
.invoice_popup_btn_check_wrap input[type="checkbox"] {
  display: none;
}

.invoice_popup_btn_container {
  display: flex;
  padding-top: 2rem;
  gap: 2rem;
  justify-content: center;
}

.invoice_popup_btn_container button {
  padding: 0.375rem 2rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}

.invoice_popup_btn_container button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.invoiceBtnDisabled {
  pointer-events: all !important;
}

/*Scroller*/
.invoice_scroller::-webkit-scrollbar {
  width: 0.5rem;
}
.invoice_scroller::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  overflow: hidden;
  padding: 0 0.25rem;
}
.invoice_scroller::-webkit-scrollbar-thumb {
  background-color: #aaa;
}
.invoice_scroller::-webkit-scrollbar-thumb:hover {
  background-color: rgb(159, 159, 159);
}
