.acceptanceForm_wrapper {
  --padding: 1rem;
  position: relative;
}

.accpt_table_wrapper {
  position: relative;
}

.accpt_table_wrapper .accpt_float {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(229, 229, 229, 0.798);
}

.acceptanceForm_wrapper.accpt_person .formGenWrapper:nth-child(n + 3),
.acceptanceForm_wrapper.accpt_comp .formGenWrapper:nth-child(n + 3) {
  --borderColor: #e5e5e5;
}
.acceptanceForm_wrapper.accpt_form .formGenWrapper:nth-child(n + 2) {
  --borderColor: #e5e5e5;
}
.acceptanceForm_wrapper .formGenWrapper > .fgRow {
  padding: var(--padding);
}
.acceptanceForm_wrapper .formGenWrapper > .fgHead {
  box-shadow: none;
  padding-left: var(--padding);
  padding-right: var(--padding);
}

#acceptance_deficit_warning .fgRow {
  background-color: #fd4d0d1a;
}

@media (max-width: 1024px) {
  .acceptanceForm_wrapper {
    --padding: 0.75rem;
  }
}

.acceptance_waste_date {
  margin-bottom: 1.125rem;
  display: flex;
  align-items: center;
  --height: 2.25rem;
}
.acceptance_waste_date p {
  display: inline-flex;
  font-weight: 600;
  margin: 0;
  font-size: 1.125rem;
  margin-right: 1rem;
}
.acceptance_waste_date_dropD_container {
  width: 9.5rem;
  color: #000;
}

.waste_add_btn_wrapper {
  display: inline-flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  margin-right: 2.5rem;
  background-color: rgb(234, 234, 234);
  padding: 0.25rem 0.5rem;
  border-radius: var(--rad);
  transition: all 200ms ease;
}

.waste_add_btn_wrapper:hover {
  background-color: rgb(225, 225, 225);
}

.waste_add_btn {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.waste_add_btn > div:nth-child(2) {
  padding-top: 2px;
}

.accpt_waste_removal_btn_wrapper,
.remove_waste_btn_warapper {
  height: var(--input_height) !important;
  align-self: stretch;
}
.quantity_accpt_wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem 0;
}
.quantity_accpt_wrapper .input_wrapper,
.documentNr_accpt_wrapper {
  border-radius: var(--rad);
  border: 1px solid var(--borderColor);
  align-items: center;
}
.documentNr_accpt_wrapper {
  border: none;
}
.quantity_accpt_wrapper .input_wrapper .unit_title {
  font-size: 0.9rem;
  padding: 0.5rem;
  padding-bottom: 0.65rem;
  padding-right: 0;
  display: flex;
  align-items: center;
}
.quantity_accpt_wrapper input,
.documentNr_accpt_wrapper input {
  height: var(--input_height);
  border: none !important;
}
.warning_label_wrapper {
  cursor: pointer;
  width: calc(var(--input_height) - 2px);
  border-left: 1px solid var(--borderColor);
  align-self: stretch;
  position: relative;
}

.waste_acceptance_legend_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  gap: 1rem;
}

.warning_label_wrapper > .accpt_float_indicator_wrapper {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: visible;
  height: 0;
  display: flex;
  justify-content: center;
  cursor: default;
  --box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  display: none;
}
.warning_label_wrapper.activeRow > .accpt_float_indicator_wrapper {
  display: flex;
}
.warning_label_wrapper:hover > .accpt_float_indicator_wrapper {
  display: flex;
}

.warning_label_wrapper .accpt_float_indicator_container {
  background-color: rgb(255, 255, 255);
  border: 1px solid #e5e5e5;
  height: fit-content;
  padding: var(--padding);
  box-shadow: var(--box-shadow);
  border-radius: var(--rad);
  min-width: 20.5rem;
  z-index: 9;
  transform-style: preserve-3d;
  transform: translateY(1rem);
  position: relative;
  display: flex;
  flex-direction: column;
}

.accpt_float_note_wrapper {
  color: #42414a;
  font-size: 0.8rem;
  line-height: 0.95rem;
  margin-top: auto;
}

p.accumtLimit_acceptance_warning {
  padding: 0.5rem 0;
  border: 1px solid #e5e5e5;
  border-left: 0;
  border-right: 0;
}

.warning_label_wrapper .accpt_float_indicator_container:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-bottom-right-radius: var(--rad);
  margin: 0 auto;
  --size: 1rem;
  width: var(--size);
  height: var(--size);
  border-right: 1px solid #d0d0d0;
  border-bottom: 1px solid #d0d0d0;
  background-color: rgb(255, 255, 255);
  transform: translateZ(1px) translateY(calc(-1 * var(--size) / 2 - 1px))
    rotateZ(-135deg);
}

.waste_acceptance_legend_wrapper > div {
  align-items: center;
  gap: 0.5rem;
  display: inline-flex;
}
.waste_acceptance_legend_wrapper .color_code {
  height: 0.75rem;
  width: 0.75rem;
  border-radius: var(--rad);
}
.waste_acceptance_legend_wrapper span {
  font-size: 0.75rem;
  align-items: center;
  display: inline-flex;
}

@media (max-width: 1000px) {
  .quantity_accpt_wrapper .input_wrapper .unit_title {
    padding: 0.5rem 0.35rem;
    padding-bottom: 0.65rem;
    padding-right: 0;
  }
  .quantity_accpt_wrapper input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .quantity_accpt_wrapper.compd input {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .warning_label_wrapper {
    width: calc(var(--input_height) - 0.5rem);
  }
}
.warning_label_wrapper.amt_neutral,
.waste_acceptance_legend_wrapper .color_code.neutral_code {
  background-color: #f2f2f2;
}
.waste_acceptance_legend_wrapper .color_code.neutral_code {
  border: 1px solid #bbb;
}

.warning_label_wrapper.amt_warning,
.waste_acceptance_legend_wrapper .color_code.warning_code {
  background-color: #fb8c00;
}
.warning_label_wrapper.amt_accumWarning,
.warning_label_wrapper.amt_danger,
.waste_acceptance_legend_wrapper .color_code.danger_code {
  background-color: #fd4b0d;
}
.warning_label_wrapper.amt_good,
.waste_acceptance_legend_wrapper .color_code.good_code {
  background-color: var(--plum-waste-pry-color);
}

.warning_label_wrapper.amt_accumWarning svg path,
.warning_label_wrapper.amt_warning svg path,
.warning_label_wrapper.amt_danger svg path,
.warning_label_wrapper.amt_good svg path {
  fill: #fff;
}

.warning_label_wrapper svg {
  opacity: 1 !important;
}

.acceptance_form_btn_wrapper {
  padding: 3rem;
}
.acceptance_form_btn_wrapper > button {
  padding: 0.375rem 2rem;
  min-width: 12rem;
  overflow-wrap: anywhere;
}
.input_weight_cont .input_bound_wrapper {
  border: none !important;
}
