.nav_link_wrapper {
  display: flex;
  padding: 2.25rem 0;
  justify-content: center;
}

.nav_link_wrapper a.link_btn {
  position: relative;
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  padding: 0.75rem 1rem;
  color: var(--defaultColor);
  min-width: 0;
  border: none;
  flex: 1;
  text-align: center;
  max-width: 15rem;
  text-transform: uppercase;
}

.nav_link_wrapper a.link_btn:hover {
  text-decoration: none;
}

.nav_link_wrapper a.link_btn.nav_on {
  color: #ffffff;
  background: var(--plum-waste-dark-color);
  border-radius: 4px;
}

.link_divider {
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.link_divider_line {
  width: 2px;
  background-color: var(--plum-waste-pry-color);
  height: 50%;
}

.nav_link_wrapper a.link_btn:last-child .link_divider > .link_divider_line,
.nav_link_wrapper a.link_btn.nav_on .link_divider > .link_divider_line,
.nav_link_wrapper a.link_btn.prevNoBorder .link_divider > .link_divider_line {
  background-color: transparent;
}
