.confirm-dialog {
	width: 100%;
	padding: 40px;

	p {
    font-size: 24px;
		text-align: center;
		margin-bottom: 30px;
	}

	.action {
		display: flex;
		flex-direction: row;
		justify-content: center;

    button {
      min-width: 180px;

      &.btn-custom {
        margin-right: 20px;
      }

      &.btn-green {
        margin-left: 20px;
      }
    }
	}
}

@media screen and (max-width: 1420px) {
}

@media screen and (max-width: 1220px) {
}

@media screen and (max-width: 1020px) {
}
