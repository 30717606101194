@import 'bootstrap/scss/_functions.scss';
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .range-datepicker-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .datetimepicker-wrapper {
      &:first-child {
        margin-right: 10px;
      }
    }

    .cal-cb {
      input[type="checkbox"] {
        display: inline;
        padding: 10px;
        background: #ffffff !important;
        opacity: unset !important;
        color: #000000 !important;
        box-shadow: none !important;
        appearance: checkbox !important;
        position: relative !important;
        width: unset !important;
        height: unset !important;
        margin-top: 10px;			
      }
    }
  }
}

@include media-breakpoint-between(xs, xxl) {
  .range-datepicker-wrapper {
    display: flex;
	  flex-direction: column;
	  justify-content: center;

    .datetimepicker-wrapper {
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}