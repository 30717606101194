@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .suggestion-wrapper {
    input.suggestion-text {
      width: 100%;

      &:focus {
        outline: none;
      }

      background-image: url(/assets/images/lens.png);
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }

    ul.suggestion-options {
      // position: absolute;
      margin-top: 20px;
      border: 1px solid #9f9f9f;
      border-radius: 3px;
      width: 100%;
      background: #ffffff;
      &.noBorder {
        border: none;
      }

      li {
        display: block;
        margin-left: -33px;
        padding: 7px 15px 5px 15px;
        cursor: pointer;
        font-size: 16px;
        border-bottom: 1px solid #999999;
      }
      li:hover {
        background-color: var(--plum-waste-pry-color);
        color: #ffffff;
      }

      &.suggestion-hide {
        display: none;
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .suggestion-wrapper {
    input.suggestion-text {
      width: 100%;

      &:focus {
        outline: none;
      }

      background-image: url(/assets/images/lens.png);
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }

    ul.suggestion-options {
      // position: absolute;
      margin-top: 20px;
      border: 1px solid #9f9f9f;
      border-radius: 3px;
      width: 100%;
      background: #ffffff;
      &.noBorder {
        border: none;
      }

      li {
        display: block;
        margin-left: -33px;
        padding: 7px 15px 5px 15px;
        cursor: pointer;
        font-size: 16px;
        border-bottom: 1px solid #999999;
      }
      li:hover {
        background-color: var(--plum-waste-pry-color);
        color: #ffffff;
      }

      &.suggestion-hide {
        display: none;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .suggestion-wrapper {
    input.suggestion-text {
      width: 100%;

      &:focus {
        outline: none;
      }

      background-image: url(/assets/images/lens.png);
      background-repeat: no-repeat;
      background-position: 98% 50%;
    }

    ul.suggestion-options {
      // position: absolute;
      margin-top: 20px;
      border: 1px solid #9f9f9f;
      border-radius: 3px;
      width: 100%;
      background: #ffffff;
      &.noBorder {
        border: none;
      }

      li {
        display: block;
        margin-left: -33px;
        padding: 7px 15px 5px 15px;
        cursor: pointer;
        font-size: 16px;
        border-bottom: 1px solid #999999;
      }
      li:hover {
        background-color: var(--plum-waste-pry-color);
        color: #ffffff;
      }

      &.suggestion-hide {
        display: none;
      }
    }
  }
}
