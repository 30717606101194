/* General styles used through are here for easy availability */

* {
  box-sizing: border-box;
  --rad: 2px;
  --defaultColor: #2a2a2a;
  --borderColor: #979797;
  --borderLteColor: #e5e5e5;
  --borderLteColor: #ccc;
  --plum-waste-pry-color: #1a9938;
  --plum-waste-dark-color: #16802f;
  --plum-waste-darker-color: #136b28;
  --plum-waste-light-color: #d3ebd9;
  --plum-waste-light-hover-color: #bcd3c2;
  --plum-waste-red: #fd4b1a;
  --genSidePadding: 7.25%;
}

.waste_summary_container_wrapper {
  padding: 2.25rem var(--genSidePadding);
}

.viewExt {
  min-height: 100vh;
}

.mg2 {
  margin-top: 1.5rem;
}

.linkable {
  width: fit-content;
  cursor: pointer;
}
.linkable:hover {
  text-decoration: underline;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.link_btn:hover,
.link_btn:visited,
.link_btn {
  text-decoration: none;
}

.floatInfo {
  position: absolute;
  --color: #fff;
  background-color: var(--color);
  padding: 0.85rem;
  max-width: 100%;
  min-width: 10rem;
  border-radius: var(--rad);
  opacity: 1 !important;
  z-index: 99;
  height: max-content;
  width: max-content;
  justify-self: center;
  display: flex;
  --borderSize: 1px;
  transform-style: preserve-3d;
  --borderColor: #ddd;
  border: var(--borderSize) solid var(--borderColor);
  --box-shadow: rgba(143, 143, 143, 0.12) 0px 2px 3px 0px;
  box-shadow: var(--box-shadow);
}

.viewMore_wrapper {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  font-weight: 500;
  justify-content: center;
  margin-top: 4px;
  margin-bottom: 1rem;
  background-color: var(--plum-waste-light-color);
  border: 1px solid #dadada;
  border: 1px solid var(--borderColor);
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.125);
}
.viewMore_wrapper span {
  cursor: pointer;
  margin-right: 0.5rem;
}
.viewMore_wrapper span:hover {
  text-decoration: underline;
}

.btn {
  position: relative;
}
.floatInfo:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border-bottom-right-radius: var(--rad);
  margin: 0 auto;
  --size: 1rem;
  width: var(--size);
  z-index: -1;
  height: var(--size);
  background-color: #fff;
  /* box-shadow: var(--box-shadow); */
  border-right: var(--borderSize) solid var(--borderColor);
  border-bottom: var(--borderSize) solid var(--borderColor);
  transform: translateZ(1px)
    translateY(calc(-1 * var(--size) / 2 + var(--borderSize))) rotateZ(45deg);
}

.inActive,
.inActive[disabled],
.inActive:focus,
.inActive:hover {
  opacity: 0.4 !important;
  box-shadow: none;
  outline: none !important;
  cursor: not-allowed !important;
}
.inActive.btn-green {
  color: #fff !important;
  pointer-events: none;
  background-color: var(--plum-waste-pry-color) !important;
}
.inActive.btn-default {
  background-color: #fff !important;
}

/* this is for image/svg/png responsiveness */
svg:hover {
  opacity: 0.8;
}

.svgHolder svg,
.img_div_contain {
  object-fit: contain;
  height: 100%;
  width: 100%;
  border-radius: inherit;
}
.img_div_cover {
  object-fit: cover;
  height: 100%;
  min-width: 100%;
  width: 100%;
  border-radius: inherit;
}
.icon_container_lg {
  width: 2rem;
}

.close-circle {
  stroke-width: 0.6px;
  stroke: #2a2a2a;
  transition: all 200ms ease;
}
.close-circle:hover {
  opacity: 0.85;
}
.noMargin {
  margin: 0 !important;
}

/* flex coboxntents */
.flex {
  display: flex;
}

.flexBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  min-height: 45px;
  padding: 0.375rem 0.25rem;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  white-space: normal;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  border-radius: 0;
  min-width: 80px;
  border-radius: var(--rad);
  cursor: pointer;
}
.flexBtn:disabled {
  user-select: none;
  pointer-events: none;
  opacity: 0.65;
}
.flexBtnNoHeight {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  border: none;
  padding: 0.375rem 0.25rem;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
  white-space: normal;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  border-radius: 0;
  min-width: 80px;
  border-radius: var(--rad);
  cursor: pointer;
}

.blurredInput {
  border-radius: var(--rad);
  display: flex;
  height: 100%;
  background-color: #efefef;
  opacity: 0.7;
  cursor: not-allowed;
}

/* Center contents */
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.jus_flex_end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

/* Direct items in a column */
.fdCol {
  display: flex;
  flex-direction: column;
}

/* Color Scheme */
.clrRed {
  border-top: none !important;
  border-bottom: none !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-right-color: rgba(255, 255, 255, 0) !important;
  border-left-color: #fd4b0d !important;
  background-color: #fd4d0d1a;
}
.clrOrange {
  border-top: none !important;
  border-bottom: none !important;
  border-color: rgba(255, 255, 255, 0) !important;
  border-right-color: rgba(255, 255, 255, 0) !important;
  border-left-color: #fb8c00 !important;
  background-color: #ffb8111a;
}

/*Ripple*/
.ripple {
  position: absolute;
  pointer-events: none;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  animation: ripple 700ms ease;
  opacity: 0;
}
@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: 0;
    transform: scale(10);
  }
}

/* Create Ellipsis */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* Button */
button {
  text-align: center;
  background-color: inherit;
  border: none !important;
  color: inherit;
}

.wastes-wrapper {
  background-color: #fff;
}

.plum_logo_wrapper {
  margin: 3rem;
  margin-bottom: 1.5rem;
}

.plum_logo_wrapper span {
  font-size: 12px;
  color: #979797;
}

.plum_logo_wrapper a {
  height: 1.25rem;
}
