@import 'bootstrap/scss/_functions.scss';
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .waste-management-form {
    width: map-get($container-max-widths, "xl");
    font-size: 16px;
  
    form {
      .form-row {
        display: grid;

        &.row1 {
          grid-template-columns: 40% 60%;
        }
  
        &.row2 {
          grid-template-columns: 100%;
        }
  
        &.row3 {
          grid-template-columns: 33% auto 33%;
        }
  
        &.row4 {
          grid-template-columns: 60% 30% 10%;

          select {
            border: none;
            margin-bottom: .3rem;
            outline: none;
            background-color: transparent;
          }

          .btn-del {
            margin-top: calc(50% - 10px);
            background-size: 24px;
            height: 32px;
          }
        }

        &.row5 {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          button {
            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
  
        label {
          font-size: 18px;
        }
  
        .cell-grid {
          padding: 10px;
        }
      }	
    }
  }	
}

@include media-breakpoint-between(lg, xxl) {
  .waste-management-form {
    width: map-get($container-max-widths, "lg");
    font-size: 16px;
  
    form {
      .form-row {
        display: grid;

        &.row1 {
          grid-template-columns: 40% 60%;
        }
  
        &.row2 {
          grid-template-columns: 100%;
        }
  
        &.row3 {
          grid-template-columns: 33% auto 33%;
        }
  
        &.row4 {
          grid-template-columns: 60% 30% 10%;

          select {
            border: none;
            margin-bottom: .3rem;
            outline: none;
            background-color: transparent;
          }

          .btn-del {
            margin-top: calc(50% - 10px);
            background-size: 24px;
            height: 32px;
          }
        }

        &.row5 {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          button {
            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
  
        label {
          font-size: 18px;
        }
  
        .cell-grid {
          padding: 10px;
        }
      }	
    }
  }	
}

@include media-breakpoint-down(lg) {
  .waste-management-form {
    width: map-get($container-max-widths, "sm");
    font-size: 16px;
  
    form {
      .form-row {
        display: grid;

        &.row1 {
          grid-template-columns: 40% 60%;
        }
  
        &.row2 {
          grid-template-columns: 100%;
        }
  
        &.row3 {
          grid-template-columns: 33% auto 33%;
        }
  
        &.row4 {
          grid-template-columns: 60% 30% 10%;

          select {
            border: none;
            margin-bottom: .3rem;
            outline: none;
            background-color: transparent;
          }

          .btn-del {
            margin-top: calc(50% - 10px);
            background-size: 24px;
            height: 32px;
          }
        }

        &.row5 {
          margin-top: 30px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          button {
            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
  
        label {
          font-size: 18px;
        }
  
        .cell-grid {
          padding: 10px;
        }
      }	
    }
  }	
}
