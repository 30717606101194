.wasteacceptance {
  margin-top: 10px;
  margin-bottom: 10rem;
  padding: 0 6%;
  width: 100%;
}

.wasteacceptance_title_header {
  padding: 3rem;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin: 0 auto;
  font-size: 22px;
}

.wasteacceptance .HOMEPAGE__section {
  display: flex;
}

.wasteacceptance .DATASET__notification {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
}

.wasteacceptance .DATASET__notification.show {
  display: block !important;
}

.wasteacceptance .DATASET__notification.show .success {
  background: var(--plum-waste-light-color) !important;
}

.wasteacceptance .DATASET__notification.show .error {
  background: #ffb3b3 !important;
}

.wasteacceptance .DATASET__submit {
  margin-top: 10px;
}

.wasteacceptance .DATASET__submit.disabled {
  color: #9f9f9f;
  background: #ffffff;
  cursor: not-allowed;
}
.wasteacceptance .waste_submit_btn.disabled {
  color: #9f9f9f;
  background: #ffffff;
  cursor: not-allowed;
  pointer-events: none;
}

.info_icon_container {
  display: flex;
}
.info_icon_container input {
  flex: 1;
}
.info_icon_container.hasBtn input {
  border: 1px solid var(--borderColor);
  border-right: none !important;
  border-radius: 2px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.info_icon_container > div {
  width: 2rem;
}

.waste_accpt_client_type_wrapper {
  width: 100%;
  max-width: 30rem;
}
.waste_accpt_client_type_item {
  display: flex;
  gap: 0 0.75rem;
  font-weight: 500;
  border-radius: var(--rad);
}
.waste_select_head {
  gap: 3rem;
  padding: 0.75rem 0;
}
.waste_accpt_header_gen .formGenWrapper:first-child .fgHead {
  box-shadow: none;
}
.waste_accpt_header_gen
  > .form_management_container
  > .formGenWrapper:first-child {
  border: none;
}
.waste_accpt_header_gen > .form_management_container > .formGenWrapper .fgHead {
  padding-left: 1rem;
  padding-right: 1rem;
}
