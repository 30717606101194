.labelCheck,
.labelRadio {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  border: 2px solid #9f9f9f;
  background-color: #fff;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}
.labelCheck.readOnly:hover,
.labelCheck.readOnly {
  border: 2px solid #dadada;
}

.labelRadio {
  padding: 0;
  border-radius: 50%;
}

input.customListCheckBox[type="checkbox"],
input.customListRadio[type="radio"] {
  display: none;
}

.labelCheck span,
.labelRadio span {
  background-color: #000 !important;
  position: absolute;
}
.labelCheck:hover,
.labelRadio:hover {
  border-color: var(--plum-waste-pry-color);
}
.labelCheckMark {
  flex: 1;
  height: 1rem;
  width: 1.2rem;
  margin-top: -4px;
  transform: rotateZ(45deg);
  display: none;
  border: 2px solid var(--plum-waste-pry-color);
  border-top: none !important;
  border-left: none !important;
  pointer-events: none; /* Important to ensure centering of the ripple*/
}

.labelRadioCircle {
  flex: 1;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background-color: #fff;
  border: 2.7px solid #fff;
  pointer-events: none; /* Important to ensure centering of the ripple*/
}

.labelCheck.isChecked:hover,
.labelCheck.isChecked {
  border-color: var(--plum-waste-pry-color);
  background-color: #fff;
}
.customListCheckBox:checked + .labelCheckMark {
  border-color: var(--plum-waste-pry-color);
  display: block;
}

.labelRadio.isChecked:hover,
.labelRadio.isChecked {
  border-color: var(--plum-waste-pry-color);
  background-color: #fff;
}
.customListRadio:checked + .labelRadioCircle {
  background-color: var(--plum-waste-pry-color);
}

.labelCheck.readOnly:hover,
.labelCheck.readOnly {
  border: 2px solid #dadada;
  cursor: default;
}

.labelCheck.readOnly:hover .labelCheckMark,
.labelCheck.readOnly .labelCheckMark {
  border-color: #dadada;
}
