.fraction_wrapper_btn_footer_wrapper {
  margin-top: 2rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}
.fraction_wrapper_btn_footer {
  display: flex;
  padding: 1rem;
  justify-content: center;
  align-items: center;
}

.fraction_dir_icon_wrapper.left {
  margin-right: 0.25rem;
}
.fraction_dir_icon_wrapper.right {
  margin-left: 0.25rem;
}
.fraction_dir_icon_wrapper svg,
.fraction_dir_icon_wrapper path {
  fill: var(--plum-waste-pry-color);
}
.fraction_dir_icon_wrapper.inactive svg,
.fraction_dir_icon_wrapper.inactive path {
  fill: #ddd;
  cursor: default;
}
.fraction_dir_span {
  cursor: pointer;
  padding: 0 0.25rem;
  color: var(--plum-waste-pry-color);
}
.fraction_dir_span:hover {
  text-decoration: underline;
}
.fraction_dir_span.active,
.fraction_dir_span.active:hover {
  cursor: default;
  color: var(--defaultColor);
  text-decoration: none;
  font-weight: 600;
}
.fraction_dir_span.non,
.fraction_dir_span.non:hover {
  cursor: default;
  color: var(--defaultColor);
  text-decoration: none;
}

.fraction_wrapper_btn_footer_wrapper .flex span {
  color: var(--plum-waste-pry-color);
}
.fraction_wrapper_btn_footer_wrapper .flex {
  align-items: center;
}
.fraction_wrapper_btn_footer_wrapper_drop {
  max-width: 4rem;
  margin-left: 1rem;
  --height: 2rem;
  line-height: var(--height) !important;
}
.fraction_wrapper_btn_footer_wrapper_drop .dropD_btn {
  /* padding: 0.7rem 0.2rem; */
  width: 1.8rem;
}
.fraction_wrapper_btn_footer_wrapper_drop .dropD_btn > div {
  /* padding: 0.7rem 0.2rem; */
  width: 0.7rem;
}
