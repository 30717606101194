.HEADER__logo {
  height: 3rem;
  max-width: 12rem;
}
.HEADER__logo a {
  height: inherit;
  display: flex;
  object-fit: contain;
}
.HEADER__logo svg:hover,
.HEADER__logo svg {
  opacity: 1;
  width: auto;
}

.HEADER__menu {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  list-style: none;
}

@media only screen and (min-width: 1201px) {
  .HEADER .HEADER__menu {
    gap: 0 30px;
  }
}
.HEADER__menu li {
  display: inline-block;
  vertical-align: middle;
}

.HEADER__menu li a {
  color: #fff;
  display: block;
  text-align: center;
  font-family: "Mukta";
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
}
.HEADER__menu li.dropdown {
  max-width: 100%;
  position: relative;
}
.HEADER__menu li.dropdown:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 0;
  background: url("../images/down-arrow.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#f2f2f2), to(#f2f2f2));
  background: url("../images/down-arrow.svg") no-repeat scroll 50% 50%,
    linear-gradient(#f2f2f2, #f2f2f2);
  height: calc(100% - 2px);
  width: 40px;
  border-left: 1px solid #9f9f9f;
  pointer-events: none;
}
.HEADER__menu li.dropdown.active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border-left: none;
  border: 1px solid #9f9f9f;
}
.HEADER__menu li.dropdown datalist {
  display: none;
  position: absolute;
  top: 35px;
  margin: 0px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.2);
  box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.2);
  width: 100%;
}

.DATASET {
  background: #f7f7f7;
  min-height: 100%;
  padding-bottom: 200px;
  height: auto;
}
.DATASET__notification {
  display: none;
  width: 100%;
  background: var(--plum-waste-light-color);
  padding: 20px;
}
.DATASET__notification-text {
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.DATASET__notification-text img {
  display: block;
  margin-right: 5px;
}
.DATASET__form {
  position: relative;
  display: -ms-grid;
  display: grid;
  grid-template-columns: repeat(7, auto);
  margin: 0 40px 40px 40px;
  border: 1px solid #9f9f9f;
  -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
}
.DATASET__form-title {
  text-align: center;
  background: var(--plum-waste-light-color);
  padding: 10px 0px;
  border-bottom: 1px solid #9f9f9f;
  display: -ms-grid;
  display: grid;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.DATASET__form-input {
  margin: 10px 5px;
}
.DATASET__form-input.DROPDOWN:after {
  height: 45px;
  top: 0;
  border-radius: 2px;
  border: 1px solid #9f9f9f;
}
.HOMEPAGE__input-select.DROP-OPTIONS {
  position: absolute;
  width: 100%;
  z-index: 10;
}
.DATASET__form-input.DROPDOWN select {
  top: 36px;
}
.documentWrapper {
  display: flex;
}
.documentWrapper p {
  margin: auto 0;
  font-size: 22px;
}
.DATASET__form-input.DATE {
  position: relative;
  width: calc(100% - 10px);
}
.DATASET__form-input.DATE input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.DATASET__form-input.DATE:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  background: url("../images/calendar.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#f2f2f2), to(#f2f2f2));
  background: url("../images/calendar.svg") no-repeat scroll 50% 50%,
    linear-gradient(#f2f2f2, #f2f2f2);
  height: 100%;
  width: 40px;
  border: 1px solid #9f9f9f;
  pointer-events: none;
  border-radius: 2px;
}
.DATASET__form-input.ADD {
  -ms-grid-column: 2;
  grid-column: 2;
}
.DATASET__form-input.ADD button {
  color: #2a2a2a;
  font-size: 14px;
  background: transparent;
  border: none;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto auto;
  grid-template-columns: auto auto;
}
.DATASET__form-input.ADD button img {
  display: block;
  margin-right: 5px;
  height: 12px;
}
.DATASET__form-input.INFO {
  position: relative;
}
.DATASET__form-input.INFO:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  right: 0;
  background: url("../images/info.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#f2f2f2), to(#f2f2f2));
  background: url("../images/info.svg") no-repeat scroll 50% 50%,
    linear-gradient(#f2f2f2, #f2f2f2);
  height: 100%;
  width: 40px;
  border: 1px solid #9f9f9f;
  pointer-events: none;
  border-radius: 2px;
}
.DATASET__form-input.INFO input {
  padding-right: 50px;
}
.DATASET__form-input.INFO.max {
  position: relative;
}
.DATASET__form-input.INFO.max:after {
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#fd4b0d), to(#fd4b0d));
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    linear-gradient(#fd4b0d, #fd4b0d);
}
.DATASET__form-input.INFO.paid {
  position: relative;
}
.DATASET__form-input.INFO.paid:after {
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#fb8c00), to(#fb8c00));
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    linear-gradient(#fb8c00, #fb8c00);
}
.DATASET__form-input.INFO.free {
  position: relative;
}
.DATASET__form-input.INFO.free:after {
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(var(--plum-waste-pry-color)), to(var(--plum-waste-pry-color)));
  background: url("../images/info-white.svg") no-repeat scroll 50% 50%,
    linear-gradient(var(--plum-waste-pry-color), var(--plum-waste-pry-color));
}
.DATASET__form-input input,
.DATASET__form-input select {
  display: block;
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  height: 45px;
  width: 100%;
  text-align: left;
  font-family: "Mukta";
  padding: 5px 10px;
  outline: none;
}
.DATASET__form-input input::-webkit-input-placeholder,
.DATASET__form-input select::-webkit-input-placeholder {
  color: #2a2a2a;
  font-size: 16px;
}
.DATASET__form-input input::-moz-placeholder,
.DATASET__form-input select::-moz-placeholder {
  color: #2a2a2a;
  font-size: 16px;
}
.DATASET__form-input input:-ms-input-placeholder,
.DATASET__form-input select:-ms-input-placeholder {
  color: #2a2a2a;
  font-size: 16px;
}
.DATASET__form-input input::-ms-input-placeholder,
.DATASET__form-input select::-ms-input-placeholder {
  color: #2a2a2a;
  font-size: 16px;
}
.DATASET__form-input input::placeholder,
.DATASET__form-input select::placeholder {
  color: #2a2a2a;
  font-size: 16px;
}
.DATASET__form-input .error {
  border: 1px solid #fd4b0d !important;
  background: #ffdbce;
}
.DATASET__form.GRID4 {
  grid-template-columns: repeat(4, 1fr);
}
.DATASET__form.GRID5 {
  grid-template-columns: repeat(5, 1fr);
}
.DATASET__form.GRID6 {
  grid-template-columns: repeat(6, 1fr);
}
.DATASET__form.GRID7 {
  grid-template-columns: repeat(7, 1fr);
}
.DATASET__submit {
  background: var(--plum-waste-pry-color);
  color: #fff;
  border: none;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 600;
  padding: 10px 40px;
  margin: 0 auto;
  display: block;
  margin-top: 40px;
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
}
.DATASET__submit.disabled {
  background: grey;
  cursor: not-allowed;
}
.DATASET__status {
  position: relative;
}

.DATASET__form.GRID6 .DATASET__form-title,
.DATASET__form.GRID7 .DATASET__form-title {
  border-top: 1px solid #9f9f9f;
}

.DATASET__disabled {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #f7f7f7;
  opacity: 0.8;
}
.DATASET__error,
.DATASET__error__alone {
  background: #ffdbce;
  bottom: -70px;
  -ms-grid-column: 1;
  padding: 15px;
  border-radius: 2px;
  color: #fd4b0d;
  font-size: 14px;
  font-weight: 600;
}
.DATASET__error {
  position: absolute;
  grid-column: 1;
}
.DATASET__error__alone {
  margin-bottom: 40px;
}
.DROPDOWN {
  max-width: 100%;
  position: relative;
}
.DROPDOWN:after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 0;
  background: url("../images/down-arrow.svg") no-repeat scroll 50% 50%,
    -webkit-gradient(linear, left top, left bottom, from(#f2f2f2), to(#f2f2f2));
  background: url("../images/down-arrow.svg") no-repeat scroll 50% 50%,
    linear-gradient(#f2f2f2, #f2f2f2);
  height: calc(100% - 2px);
  width: 40px;
  border-left: 1px solid #9f9f9f;
  pointer-events: none;
}
.DROPDOWN.active:after {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  border-left: none;
  border: 1px solid #9f9f9f;
}
.DROPDOWN datalist {
  display: none;
  position: absolute;
  top: 35px;
  margin: 0px;
  background: #fff;
  -webkit-box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.2);
  box-shadow: 0px 3px 6px rgba(38, 38, 38, 0.2);
  width: 100%;
}
.DROPDOWN input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  font-family: "Mukta";
  font-size: 16px;
  padding: 5px 10px;
  color: #2a2a2a;
  border: 1px solid #9f9f9f;
  border-radius: 2px;
  padding-right: 49px;
  cursor: pointer;
  outline: none;
  background: #fff;
  width: 100%;
}

.DATASET__form-limits {
  position: absolute;
  left: calc(25% - 10px);
  top: 56px;
  background: #fff;
  border: 1px solid #9f9f9f;
  border-radius: 2px;
  width: 150%;
}
.DATASET__form-limits:before {
  content: "";
  display: block;
  position: absolute;
  border-style: solid;
  border-color: #9f9f9f;
  border-width: 1px 0 0 1px;
  width: 15px;
  height: 15px;
  top: -9px;
  right: 50%;
  background: inherit;
  transform: translateX(-50%);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.DATASET__form-limits-agree {
  color: #fff;
  background: var(--plum-waste-pry-color);
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.DATASET__form-limits-paid {
  color: #fff;
  background: #fb8c00;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}
.DATASET__form-limits-maximum {
  color: #fff;
  background: #fd4b0d;
  padding: 5px;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 15px;
}
.DATASET__form-limits-text1,
.DATASET__form-limits-text2,
.DATASET__form-limits-text3 {
  font-size: 16px;
  color: #2a2a2a;
  font-family: "Mukta";
  padding: 20px 15px;
}
.DATASET__form-limits-text2 {
  display: none;
}
.DATASET__form-limits-text3 {
  display: none;
}
.DATASET__form-limits-button {
  border: none;
  border-top: 1px solid #9f9f9f;
  background: #f2f2f2;
  width: 100%;
  padding: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #2a2a2a;
}

.DATASET__form-input-checkboxes {
  display: grid;
  grid-template-columns: repeat(4, auto);
  margin-top: 40px;
  grid-column-gap: 5px;
}

.DATASET__form-input-checkboxes input[type="number"] {
  margin-left: 5px;
  height: 45px;
}

.DATASET__form-input-checkboxes.gap label {
  margin-top: 5px;
  height: auto;
  width: 100% !important;
  text-align: center;
}

.DATASET__form-input-checkboxes label,
.DATASET__form-input label,
.sitesOptions label {
  display: block;
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  position: relative;
  padding: 5px 10px;
  background: #fff;
  color: #2a2a2a;
  z-index: 12;
  margin-bottom: 0px;
}

.sitesOptions label input {
  border: none !important;
}

.DATASET__form-input-checkboxes input[type="radio"],
.DATASET__form-input-checkboxes input[type="checkbox"],
.DATASET__form-input input[type="checkbox"],
.sitesOptions input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  left: 0;
  cursor: pointer;
  z-index: 1;
  height: 100%;
}

.DATASET__form-input-checkboxes input[type="checkbox"]:checked,
.DATASET__form-input input[type="checkbox"]:checked,
.sitesOptions input[type="checkbox"]:checked,
.DATASET__form-input-checkboxes input[type="radio"]:checked {
  background: var(--plum-waste-pry-color);
  opacity: 0.3;
  color: #fff;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 20%);
}

.DATASET__form.settings::-webkit-scrollbar {
  display: none;
}
.DATASET__form.settings {
  padding: 0px;
  border: none;
  margin: 40px 0px;
}
.DATASET__form.settings .DATASET__form-input input {
  height: 45px !important;
}

/* OVERLAY */

/* #overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  cursor: pointer;
}

#overlay-container {
  max-width: min-content;
  background-color: white;
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  padding: 60px;
} */

#overlay {
  position: fixed;
  /* display: block; */
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  cursor: pointer;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
}

#overlay-container {
  max-width: min-content;
  background-color: white;
  position: relative;
  padding: 60px;
  overscroll-behavior: contain;
  overflow-y: auto;
  border-radius: 0.25rem;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

#overlay-x {
  position: absolute;
  top: -15px;
  right: -15px;
  cursor: pointer;
  z-index: 10;
}

/* POP UP */

.pop-up {
  width: 500px;
}

.pop-up-wrapper {
  display: flex;
  flex-direction: column;
}

.pop-up-wrapper label {
  margin: 20px 0;
}

.pop-up .pop-up-buttons {
  display: flex;
}

.pop-up .pop-up-buttons button:nth-child(1) {
  margin-right: 10px;
}

/* WASTE RECEIVED */

.newWaste-received {
  padding: 40px;
}

.newWaste-received-item,
.newWaste-received-titles {
  display: grid;
  grid-template-columns: 70% 30%;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: -1px;
}

.newWaste-received-titles div {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.newWaste-received-item-amount,
.newWaste-received-item-waste {
  border: 1px solid #000;
  padding: 20px;
}

/* DROPDOWN */

.DATASET__form-input .dropdown {
  position: absolute;
  width: 200px;
  height: auto;
  margin: 0;
  margin-top: 3px;
  display: flex;
  flex-direction: column;
  -webkit-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  -moz-box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}
.DATASET__form-input .dropdown p {
  display: flex;
  align-items: center;
  height: 40px;
  background-color: #fff;
  margin: 0;
  padding-left: 10px;
  cursor: default;
  font-size: 13px;
}

.DATASET__form-input .dropdown p:hover {
  background-color: #cecece;
}

.newWaste-received {
  padding: 0px;
  margin: 40px;
  box-shadow: 0px 3px 3px rgb(0 0 0 / 16%);
  border: 1px solid #9f9f9f;
}

.newWaste-received-titles {
  gap: 0px;
  border-bottom: 1px solid #9f9f9f;
  background: var(--plum-waste-light-color);
  margin-top: 0px;
}

.newWaste-received-titles div {
  margin-bottom: 0px;
  padding: 20px;
}

.newWaste-received-item {
  gap: 0px;
  margin: 10px auto;
}

.newWaste-received-item-waste,
.newWaste-received-item-amount {
  background: #fff;
  border: 1px solid #9f9f9f !important;
  border-radius: 2px;
  margin: 0px 10px;
  height: 100%;
}

.false {
  width: 190px;
}

.DROP-OPTIONS {
  height: 400px;
  overflow-y: auto;
}

.HOMEPAGE__input-select.option {
  height: 500px;
  overflow-y: auto;
}
