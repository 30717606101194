.waste-journal {
  margin-top: 10px;
  margin-bottom: 300px;

  .journal_upload {
    // opacity: 0.2;
  }

  .journal_expand_head {
    height: 100%;
  }

  .journal_icon_holder.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  .waste_journal_toggle {
    text-align: center;
    margin: 0 auto;
  }

  .journalFormMin {
    min-height: 30rem;
  }

  .HOMEPAGE__section-custom {
    align-items: center !important;
    padding: 40px;
    display: flex;
    justify-content: flex-start;

    .nav-link {
      display: flex !important;
      justify-content: center;
    }
  }

  .DATASET__header-custom {
    position: relative;
  }

  .DATASET__wrapper {
    border: 1px solid #999999;
    background-color: #ffffff !important;

    &.expanded {
      overflow-x: scroll;
      overflow-y: hidden;
    }

    .DATASET__form-custom {
      position: relative;
      display: grid;

      &.normal {
        grid-template-columns: 15% 20% 15% 15% 10% 10% 5% 10%;
      }

      &.expanded {
        grid-template-columns: 15% 15% 15% 15% 15% 15% 5% 15% 15% 10% 10% 10% 10%;
      }

      .DATASET__form-title {
        border-bottom: 1px solid #9f9f9f;
        font-size: 16px;
      }

      .DATASET__form-input {
        &.DROPDOWN {
          &:after {
            top: 20%;
            right: 5%;
          }
        }

        .range-datepicker-wrapper {
          display: flex;
          flex-direction: row;

          .datetimepicker-wrapper:first-child {
            margin-bottom: 0;
            margin-right: 10px;
          }
        }
      }
    }

    .DATASET__data {
      position: relative;
      display: grid;
      mask-size: cover;
      mask-repeat: no-repeat;
      mask-position: left;

      &.normal {
        grid-template-columns: 15% 20% 15% 15% 10% 10% 5% 10%;
        mask-image: linear-gradient(
          to right,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0)
        );
      }

      &.expanded {
        grid-template-columns: 15% 15% 15% 15% 15% 15% 5% 15% 15% 10% 10% 10% 10%;
      }

      .DATASET__form-input {
        border-top: 1px solid #9f9f9f;
        margin: 0 !important;
        padding: 15px;
      }
    }
  }

  .btn-more {
    word-wrap: anywhere;
    align-self: stretch;
    border-radius: 2px;
    font-weight: 500;
    border: 1px solid var(--borderColor) !important;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3),
      0 1px 3px 1px rgba(60, 64, 67, 0.3);

    border: 0;
    display: flex;
    background-color: var(--plum-waste-light-color);
    gap: 0.5rem;
    box-sizing: border-box;
    font-weight: 600;
    align-items: center;
    width: 100%;
    max-width: 12rem;
    // background: url(/assets/images/more.svg) no-repeat scroll 10% 50% #ffffff;
    &:hover {
      background-color: var(--plum-waste-light-hover-color);
    }
    svg {
      height: 1rem;
      width: auto;
    }
    svg rect {
      fill: #000;
    }
    svg:hover {
      opacity: 0.8;
    }
    span {
      flex: 1;
      display: block;
      width: 90%;
      width: fit-content;
      margin: auto 0 auto auto;
    }
  }

  .btn-less {
    font-weight: bold;
    width: 100%;
    padding: 10px;
    border: 0;
    display: block;
    box-sizing: border-box;
    background: url(/assets/images/minus.svg) no-repeat scroll 10% 50%;

    span {
      display: block;
      width: 80%;
      margin: auto 0 auto auto;
      text-align: center;
    }
  }
}

@media screen and (max-width: 991px) {
  .DATASET__form-input input,
  .DATASET__form-input select {
    height: 33px !important;
  }

  .DATASET__form-input .multi-dropdown-wrapper:after {
    width: 24px;
    height: calc(100% - 2px);
  }
}

@media screen and (max-width: 1399px) {
  .DATASET__form-input input,
  .DATASET__form-input select {
    height: 35px !important;
  }

  .DATASET__form-input .multi-dropdown-wrapper:after {
    height: 33px;
    width: 30px;
  }
}

@media screen and (max-width: 1680px) {
  .waste-journal {
    .DATASET__wrapper {
      &.normal {
        overflow-x: scroll;
        overflow-y: hidden;
      }

      .DATASET__form-custom {
        &.normal {
          grid-template-columns: 240px 360px 240px 240px 240px 240px 100px 200px;
        }

        &.expanded {
          grid-template-columns: 25% 20% 25% 25% 25% 25% 10% 25% 25% 20% 20% 15% 15%;
        }
      }

      .DATASET__data {
        &.normal {
          grid-template-columns: 240px 360px 240px 240px 240px 240px 100px 200px;
          mask-image: none;
        }

        &.expanded {
          grid-template-columns: 25% 20% 25% 25% 25% 25% 10% 25% 25% 20% 20% 15% 15%;
        }
      }
    }
  }
}
