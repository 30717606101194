@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .disposal {
    margin-top: 10px;

    input[type="checkbox"] {
      appearance: checkbox !important;
      position: relative !important;
      height: 33px !important;

      &:checked {
        background: unset !important;
        opacity: unset !important;
        color: unset !important;
        box-shadow: unset !important;
      }
    }

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .home-link {
      }

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        // display: grid;
        grid-template-columns: 24% 30% 13% 13% 20%;
        border: 1px solid #999999;

        // .DATASET__form-title {
        //   border-bottom: 1px solid #9f9f9f;
        //   font-size: 16px;
        //   padding: 20px !important;

        //   .sorting-link {
        //     cursor: pointer;

        //     &:hover {
        //       text-decoration: underline;
        //     }
        //   }
        // }

        .DATASET__form-input {
          margin: 0 !important;
          padding: 15px;

          &.DROPDOWN {
            &:after {
              top: 20%;
              right: 4%;
            }
          }
        }
      }

      .DATASET__data {
        position: relative;

        .site-wrapper {
          display: grid;
          grid-template-columns: 25% 35% 10% 10% 20%;

          .DATASET__form-input {
            border-top: 1px solid #9f9f9f;
            margin: 0 !important;
            padding: 15px;
            background: #f7f7f7;

            &.flex-col {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            }

            &.flex-row {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
            }

            &.hover {
              background-color: #fffaee;
            }

            .edit-link {
              font-weight: bold;
              cursor: pointer;
            }

            &.clr-orange {
              background: #fff5d9;

              &.with-border {
                border-left: 5px solid #ffbc00;
              }
            }

            &.clr-red {
              background: #ffeded;

              &.with-border {
                border-left: 5px solid #ff0000;
              }
            }

            &.site-title {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .icon-warning {
                width: 45px;
                height: 45px;
                background: url(/assets/images/info.svg) no-repeat scroll 50%
                  50%;
              }
            }
          }
        }

        // .waste-wrapper {
        //   display: grid;
        //   grid-template-columns: 24% 30% 13% 13% 20%;
        //   border: 1px solid #9f9f9f;
        //   box-shadow: 0px 3px 1.5px rgba(0, 0, 0, 0.16);

        //   .DATASET__form-input {
        //     // border-top: 1px solid #9F9F9F;
        //     margin: 0 !important;
        //     padding: 15px;

        //     &.flex-col {
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: flex-start;
        //       align-items: center;
        //     }

        //     &.flex-row {
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //       align-items: flex-start;
        //     }

        //     &.hover {
        //       background-color: #fffaee;
        //     }

        //     .edit-link {
        //       font-weight: bold;
        //       cursor: pointer;
        //     }

        //     &.clr-orange {
        //       background: #fff5d9;

        //       &.with-border {
        //         border-left: 5px solid #ffbc00;
        //       }
        //     }

        //     &.clr-red {
        //       background: #ffeded;

        //       &.with-border {
        //         border-left: 5px solid #ff0000;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .disposal {
    margin-top: 10px;

    input[type="checkbox"] {
      appearance: checkbox !important;
      position: relative !important;
      height: 33px !important;

      &:checked {
        background: unset !important;
        opacity: unset !important;
        color: unset !important;
        box-shadow: unset !important;
      }
    }

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .home-link {
      }

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        // display: grid;
        grid-template-columns: 24% 30% 13% 13% 20%;
        border: 1px solid #999999;

        // .DATASET__form-title {
        //   border-bottom: 1px solid #9f9f9f;
        //   font-size: 16px;
        //   padding: 20px !important;

        //   .sorting-link {
        //     cursor: pointer;

        //     &:hover {
        //       text-decoration: underline;
        //     }
        //   }
        // }

        .DATASET__form-input {
          margin: 0 !important;
          padding: 15px;

          &.DROPDOWN {
            &:after {
              top: 20%;
              right: 4%;
            }
          }
        }
      }

      .DATASET__data {
        position: relative;

        .site-wrapper {
          display: grid;
          grid-template-columns: 25% 35% 10% 10% 20%;

          .DATASET__form-input {
            border-top: 1px solid #9f9f9f;
            margin: 0 !important;
            padding: 15px;
            background: #f7f7f7;

            &.flex-col {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            }

            &.flex-row {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
            }

            &.hover {
              background-color: #fffaee;
            }

            .edit-link {
              font-weight: bold;
              cursor: pointer;
            }

            &.clr-orange {
              background: #fff5d9;

              &.with-border {
                border-left: 5px solid #ffbc00;
              }
            }

            &.clr-red {
              background: #ffeded;

              &.with-border {
                border-left: 5px solid #ff0000;
              }
            }

            &.site-title {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .icon-warning {
                width: 45px;
                height: 45px;
                background: url(/assets/images/info.svg) no-repeat scroll 50%
                  50%;
              }
            }
          }
        }

        // .waste-wrapper {
        //   display: grid;
        //   grid-template-columns: 24% 30% 13% 13% 20%;
        //   // border: 1px solid #d8d8d8;
        //   box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.125);

        //   .DATASET__form-input {
        //     // border-top: 1px solid #9F9F9F;
        //     margin: 0 !important;
        //     padding: 15px;

        //     &.flex-col {
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: flex-start;
        //       align-items: center;
        //     }

        //     &.flex-row {
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //       align-items: flex-start;
        //     }

        //     &.hover {
        //       background-color: #fffaee;
        //     }

        //     .edit-link {
        //       font-weight: bold;
        //       cursor: pointer;
        //     }

        //     &.clr-orange {
        //       background: #fff5d9;

        //       &.with-border {
        //         border-left: 5px solid #ffbc00;
        //       }
        //     }

        //     &.clr-red {
        //       background: #ffeded;

        //       &.with-border {
        //         border-left: 5px solid #ff0000;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .disposal {
    margin-top: 10px;

    input[type="checkbox"] {
      appearance: checkbox !important;
      position: relative !important;
      height: 33px !important;

      &:checked {
        background: unset !important;
        opacity: unset !important;
        color: unset !important;
        box-shadow: unset !important;
      }
    }

    .HOMEPAGE__section-custom {
      align-items: center !important;
      padding: 40px;
      display: flex;
      justify-content: flex-start;

      .nav-link {
        display: flex !important;
        justify-content: center;
      }
    }

    .DATASET__header-custom {
      position: relative;
    }

    .DATASET__wrapper {
      background-color: #ffffff !important;

      .DATASET__form-custom {
        position: relative;
        // display: grid;
        grid-template-columns: 24% 30% 13% 13% 20%;
        border: 1px solid #999999;

        // .DATASET__form-title {
        //   border-bottom: 1px solid #9f9f9f;
        //   font-size: 16px;
        //   padding: 20px !important;

        //   .sorting-link {
        //     cursor: pointer;

        //     &:hover {
        //       text-decoration: underline;
        //     }
        //   }
        // }

        .DATASET__form-input {
          margin: 0 !important;
          padding: 15px;

          &.DROPDOWN {
            &:after {
              top: 20%;
              right: 4%;
            }
          }
        }
      }

      .DATASET__data {
        position: relative;

        .site-wrapper {
          display: grid;
          grid-template-columns: 25% 35% 10% 10% 20%;

          .DATASET__form-input {
            border-top: 1px solid #9f9f9f;
            margin: 0 !important;
            padding: 15px;
            background: #f7f7f7;

            &.flex-col {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: center;
            }

            &.flex-row {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: flex-start;
            }

            &.hover {
              background-color: #fffaee;
            }

            .edit-link {
              font-weight: bold;
              cursor: pointer;
            }

            &.clr-orange {
              background: #fff5d9;

              &.with-border {
                border-left: 5px solid #ffbc00;
              }
            }

            &.clr-red {
              background: #ffeded;

              &.with-border {
                border-left: 5px solid #ff0000;
              }
            }

            &.site-title {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;

              .icon-warning {
                width: 45px;
                height: 45px;
                background: url(/assets/images/info.svg) no-repeat scroll 50%
                  50%;
              }
            }
          }
        }

        // .waste-wrapper {
        //   display: grid;
        //   grid-template-columns: 24% 30% 13% 13% 20%;
        //   border: 1px solid #9f9f9f;
        //   box-shadow: 0px 3px 1.5px rgba(0, 0, 0, 0.16);

        //   .DATASET__form-input {
        //     // border-top: 1px solid #9F9F9F;
        //     margin: 0 !important;
        //     padding: 15px;

        //     &.flex-col {
        //       display: flex;
        //       flex-direction: column;
        //       justify-content: flex-start;
        //       align-items: center;
        //     }

        //     &.flex-row {
        //       display: flex;
        //       flex-direction: row;
        //       justify-content: center;
        //       align-items: flex-start;
        //     }

        //     &.hover {
        //       background-color: #fffaee;
        //     }

        //     .edit-link {
        //       font-weight: bold;
        //       cursor: pointer;
        //     }

        //     &.clr-orange {
        //       background: #fff5d9;

        //       &.with-border {
        //         border-left: 5px solid #ffbc00;
        //       }
        //     }

        //     &.clr-red {
        //       background: #ffeded;

        //       &.with-border {
        //         border-left: 5px solid #ff0000;
        //       }
        //     }
        //   }
        // }
      }
    }
  }
}
