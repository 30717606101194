.wasteAccumulation {
  margin-top: 10px;
}

.wasteAccumulation_edit_icon path {
  fill: rgb(202, 202, 202);
  opacity: 1;
}

.wasteAccumulation_edit_icon:hover path {
  fill: var(--plum-waste-dark-color);
  opacity: 1;
}

.waste_accum_toggle {
  cursor: pointer;
  height: fit-content;
  width: fit-content;
}
.waste_accum_toggle:hover {
  text-decoration: underline;
}
.wasteAccumForm_wrap {
  padding-left: min(10%, 10rem) !important;
  padding-right: min(10%, 10rem) !important;
}
.wasteAccumPopUp {
  min-width: auto !important;
  max-width: 50rem !important;
  flex: none;
  overflow-x: hidden;
}
.wasteAccumuation .newPopUp_wrap {
}
