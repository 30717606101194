.disposal {
  --height: 45px;
  margin-bottom: 20rem;
}

.DATASET__wrapper {
  margin: 0;
}

.disposal_filter_wrapper {
  background-color: var(--plum-waste-light-color);
  border: 1px solid #9f9f9f;
  padding: 1rem;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.125);
}

.DATASET__form-title {
  font-size: 16px;
  padding: 20px !important;
}

.sorting-link {
  cursor: pointer;
  font-size: 1.125rem;
  font-weight: 600;
}

.sorting-link:hover {
  text-decoration: underline;
}

.site-wrapper {
  height: 100px;
  align-items: flex-end;
  display: flex;
  padding: 1rem 0;
}

.site-wrapper > span {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.site-wrapper > span span {
  width: 4rem;
  padding: 0 1rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disposal_btn {
  padding: 1rem;
}

.disposal_btn:hover {
  padding: 1rem;
  background-color: var(--plum-waste-pry-color);
}

.flex1 {
  flex: 1;
}
.flexEnd {
  display: flex;
  justify-content: flex-end;
}

.waste-wrapper {
  display: block;
  background-color: #fff;
  flex-direction: column;
  border: 1px solid #d8d8d8;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.125);
}

.disposal_filter_wrapper,
.waste_container {
  --padding: 2rem;
  display: grid;
  grid-template-columns: 5.5fr 5.5fr 9fr;
  gap: 0 1rem;
}

.waste_container {
  padding: var(--padding) 1rem;
  border-bottom: 1px solid #e5e5e5;
  border-right: 6px solid transparent;
  border-left: 6px solid transparent;
}
.waste_container:last-child {
  border-bottom: none;
}
.waste_container > div {
  padding: 0 1rem;
}

.waste_container > div:first-child {
  padding-left: var(--padding);
}

.disposal_filter_wrapper > div:last-child,
.waste_container > div:last-child {
  padding-right: var(--padding);
}

.disposal_title_col {
  align-items: flex-start;
}
.disposal_title_col > div {
  flex: 1;
}

.disposal_vol_col {
  flex: 9;
  display: flex;
  justify-content: space-around;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0 1rem;
  padding-left: 1rem;
  padding-right: var(--padding);
}

.disposal_vol_col > div {
  min-width: 0;
  display: flex;
  text-align: center;
  word-break: break-all;
  align-items: center;
}

.disposal_vol_col > div:last-child {
  justify-content: flex-end;
}

.disposal_vol_col input[type="checkbox"] {
  display: none;
}

.labelCheck {
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  border-radius: 2px;
  border: 2px solid #9f9f9f;
  background-color: #fff;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 200ms ease;
  margin: 0;
}
.labelCheck span {
  background-color: #000 !important;
  position: absolute;
}
.labelCheck:hover {
  border-color: var(--plum-waste-pry-color);
}
.labelCheckMark {
  flex: 1;
  height: 1rem;
  width: 1.2rem;
  margin-top: -4px;
  transform: rotateZ(45deg);
  display: none;
  border: 2px solid var(--plum-waste-pry-color);
  border-top: none !important;
  border-left: none !important;
  transition: all 200ms ease;
}

.disposalListCheckBox:checked + .labelCheck:hover,
.disposalListCheckBox:checked + .labelCheck {
  border-color: var(--plum-waste-pry-color);
  background-color: #fff;
}
.disposalListCheckBox:checked + .labelCheck .labelCheckMark {
  border-color: var(--plum-waste-pry-color);
  display: block;
}

/* Drop Down */
.dropDown {
  position: relative;
  --rad: 3px;
}

.dropDown_wrapper {
  display: flex;
  border: 1px solid #9f9f9f;
  border-radius: var(--rad);
  overflow: hidden;
}
.dropDown_wrapper.drop {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.dropDown_wrapper input {
  border: none !important;
  border-radius: 0 !important;
  height: var(--height);
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.dropDown_wrapper input::placeholder {
  font-size: 0.9rem;
  color: rgb(159, 159, 159);
}
.dropDown_wrapper input:focus {
  border: none;
}

.dropDown_btn {
  width: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  border-left: 1px solid #9f9f9f;
  transition: all 200ms ease;
}
.dropDown_btn:hover {
  background-color: #e5e5e5;
}

.dropDown_btn > div {
  width: 1rem;
  transition: all 150ms ease;
}

/* Uncomment to rotate the drop button on click */
/* .dropDown_wrapper.drop .dropDown_btn > div {
  transform: rotateZ(-180deg);
} */

.dropDown_container {
  border: 1px solid #9f9f9f;
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  top: calc(var(--height) + 2px);
  height: max-content;
  font-size: 1rem;
  max-height: 18rem;
  width: 100%;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 2px 6px rgba(71, 71, 71, 0.125);
  border-bottom-left-radius: var(--rad);
  border-bottom-right-radius: var(--rad);
  border-top: none;
  transition: all 300ms ease;
  overscroll-behavior: contain;
}

.dropDown_content {
  cursor: pointer;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  border-bottom: 1px solid #dadada;
}
.dropDown_content:hover {
  color: #fff;
  background-color: var(--plum-waste-pry-color);
}
.dropDown_content.picked,
.dropDown_content.picked:hover {
  color: #000;
  background-color: var(--plum-waste-light-color);
}
.dropDown_content:last-child {
  border-bottom: none;
}

.dropDown_content > span {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100px;
  height: 100px;
  margin-top: -50px;
  margin-left: -50px;
  animation: ripple 800ms ease;
  opacity: 0;
}
/* Scroller CSS for Drop Down */
.dropDown_container::-webkit-scrollbar {
  width: 0.75rem;
}
.dropDown_container::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  /* border-radius: 1rem; */
  overflow: hidden;
  padding: 0 0.25rem;
}
.dropDown_container::-webkit-scrollbar-thumb {
  background-color: #aaa;
}
.dropDown_container::-webkit-scrollbar-thumb:hover {
  background-color: rgb(159, 159, 159);
}

.weight_adjustment_wrapper {
  display: flex;
  grid-gap: 0.5rem 0;
  gap: 0.5rem 0;
}

.weight_adjustment_wrapper .input_wrapper {
  border-radius: var(--rad);
  border: 1px solid var(--borderColor);
  align-items: center;
}

.weight_adjustment_wrapper input {
  border: none !important;
}

.weight_adjustment_wrapper .input_wrapper .unit_title {
  font-size: 0.9rem;
  padding: 0.5rem;
  padding-bottom: 0.65rem;
  padding-right: 0;
  display: flex;
  align-items: center;
}

.weight_adjustment_input {
  width: 100px !important;
}
