@import 'bootstrap/scss/_functions.scss';
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/mixins/_breakpoints.scss";

@include media-breakpoint-up(xxl) {
  .signin {
    padding-top: 100px;
  
    .panel-login {
      border-color: #ccc;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      padding: 20px;
      background-color: #ffffff;
      text-align: left;
  
      >.panel-heading {
        color: #00415d;
        background-color: #fff;
        border-color: #fff;
        text-align:center;
  
        h3 {
          text-align: left;
        }
  
        a {
          text-decoration: none;
          color: #666;
          font-weight: bold;
          font-size: 15px;
          -webkit-transition: all 0.1s linear;
          -moz-transition: all 0.1s linear;
          transition: all 0.1s linear;
        }
        
        a.active {
          color: #029f5b;
          font-size: 18px;
        }
  
        hr {
          margin-top: 10px;
          margin-bottom: 0px;
          clear: both;
          border: 0;
          height: 1px;
          background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
          background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
        }
      }
  
      .form-group {
        margin-top: 20px;
      }
  
      input[type="text"], input[type="email"], input[type="password"] {
        height: 45px;
        border: 1px solid #ddd;
        font-size: 16px;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        transition: all 0.1s linear;
      }
  
      input:hover, input:focus {
        outline:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: #ccc;
      }
  
      .txt-error {
        margin-bottom: 20px;
      }
    }
    
    .btn-login {
      background-color: #59B2E0;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #59B2E6;
  
      :hover, :focus {
        color: #fff;
        background-color: #53A3CD;
        border-color: #53A3CD;
      }
    }
  
    .forgot-password {
      text-decoration: underline;
      color: #888;
  
      :hover, :focus {
        text-decoration: underline;
        color: #666;
      }
    }
  
    .btn-register {
      background-color: #1CB94E;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #1CB94A;
  
      :hover, :focus {
        color: #fff;
        background-color: #1CA347;
        border-color: #1CA347;
      }
    }
  }
}

@include media-breakpoint-between(lg, xxl) {
  .signin {
    padding-top: 100px;
  
    .panel-login {
      border-color: #ccc;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      padding: 20px;
      background-color: #ffffff;
      text-align: left;
  
      >.panel-heading {
        color: #00415d;
        background-color: #fff;
        border-color: #fff;
        text-align:center;
  
        h3 {
          text-align: left;
        }
  
        a {
          text-decoration: none;
          color: #666;
          font-weight: bold;
          font-size: 15px;
          -webkit-transition: all 0.1s linear;
          -moz-transition: all 0.1s linear;
          transition: all 0.1s linear;
        }
        
        a.active {
          color: #029f5b;
          font-size: 18px;
        }
  
        hr {
          margin-top: 10px;
          margin-bottom: 0px;
          clear: both;
          border: 0;
          height: 1px;
          background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
          background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
        }
      }
  
      .form-group {
        margin-top: 20px;
      }
  
      input[type="text"], input[type="email"], input[type="password"] {
        height: 45px;
        border: 1px solid #ddd;
        font-size: 16px;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        transition: all 0.1s linear;
      }
  
      input:hover, input:focus {
        outline:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: #ccc;
      }
  
      .txt-error {
        margin-bottom: 20px;
      }
    }
    
    .btn-login {
      background-color: #59B2E0;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #59B2E6;
  
      :hover, :focus {
        color: #fff;
        background-color: #53A3CD;
        border-color: #53A3CD;
      }
    }
  
    .forgot-password {
      text-decoration: underline;
      color: #888;
  
      :hover, :focus {
        text-decoration: underline;
        color: #666;
      }
    }
  
    .btn-register {
      background-color: #1CB94E;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #1CB94A;
  
      :hover, :focus {
        color: #fff;
        background-color: #1CA347;
        border-color: #1CA347;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .signin {
    padding-top: 100px;
  
    .panel-login {
      border-color: #ccc;
      -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
      padding: 20px;
      background-color: #ffffff;
      text-align: left;
  
      >.panel-heading {
        color: #00415d;
        background-color: #fff;
        border-color: #fff;
        text-align:center;
  
        h3 {
          text-align: left;
        }
  
        a {
          text-decoration: none;
          color: #666;
          font-weight: bold;
          font-size: 15px;
          -webkit-transition: all 0.1s linear;
          -moz-transition: all 0.1s linear;
          transition: all 0.1s linear;
        }
        
        a.active {
          color: #029f5b;
          font-size: 18px;
        }
  
        hr {
          margin-top: 10px;
          margin-bottom: 0px;
          clear: both;
          border: 0;
          height: 1px;
          background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
          background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
          background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
        }
      }
  
      .form-group {
        margin-top: 20px;
      }
  
      input[type="text"], input[type="email"], input[type="password"] {
        height: 45px;
        border: 1px solid #ddd;
        font-size: 16px;
        -webkit-transition: all 0.1s linear;
        -moz-transition: all 0.1s linear;
        transition: all 0.1s linear;
      }
  
      input:hover, input:focus {
        outline:none;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        border-color: #ccc;
      }
  
      .txt-error {
        margin-bottom: 20px;
      }
    }
    
    .btn-login {
      background-color: #59B2E0;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #59B2E6;
  
      :hover, :focus {
        color: #fff;
        background-color: #53A3CD;
        border-color: #53A3CD;
      }
    }
  
    .forgot-password {
      text-decoration: underline;
      color: #888;
  
      :hover, :focus {
        text-decoration: underline;
        color: #666;
      }
    }
  
    .btn-register {
      background-color: #1CB94E;
      outline: none;
      color: #fff;
      font-size: 14px;
      height: auto;
      font-weight: normal;
      padding: 14px 0;
      text-transform: uppercase;
      border-color: #1CB94A;
  
      :hover, :focus {
        color: #fff;
        background-color: #1CA347;
        border-color: #1CA347;
      }
    }
  }
}
