.limitia_wrapper {
  margin-top: var(--mTop);
  margin-bottom: 10rem;
}
.limitia_box:nth-child(2) {
  /* border: 1px solid var(--borderColor); */
  align-self: flex-start;
}
.limitia_box {
  background-color: #fff;
  border-radius: 2px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0.8rem;
  min-height: 3rem;
  text-align: center;
}

.limitia_box:nth-child(1),
.limitia_box:nth-child(2),
.limitia_box:nth-child(3) {
  margin-top: 0;
}
.limitia_box_progress {
  position: relative;
  flex: 1;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  border: 1px solid var(--borderColor);
  border-radius: 2px;
  background-color: #fff;
}
.limitia_box_progress_text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.limitia_box_progress_handle {
  background-color: var(--plum-waste-red);
  position: absolute;
  top: 0;
  height: 100%;
  /* width: 4rem; */
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit;
  opacity: 0.56;
}

.limitia_box_tab {
  --pad: 0.75rem;
  padding: var(--pad);
  gap: var(--pad);
  grid-template-columns: 2fr 2fr 1fr;
  align-items: stretch;
}

.limitia_box_tab.fgRow {
  border-bottom: 1px solid #e5e5e5;
}
.limitia_box_tab:last-child {
  border-bottom: none;
}

.limitia_box_tab.fgRow {
  padding: var(--pad);
  padding-bottom: var(--pad);
}

.limitia_box_tab.fgRow:first-child {
  padding-top: var(--pad);
}
.limit_box_wrapper {
}
